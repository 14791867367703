import { makeObservable, observable, action, runInAction, toJS } from 'mobx';
import {
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  SelectedFilterIds,
  TableColumn,
  TableColumnConfigurable,
  defaultPaginationConfig,
  generatePages,
  toggleColumns,
} from '../modals/table.modal';
import { QueryParamDTO } from '../../shared/dto/table.dto';

export abstract class ListBaseStore<T> {
  isLoading: boolean = false;
  isInitialLoading: boolean = true;
  isEmptyList: boolean = true;
  isPagination: boolean = true;
  items: T[] = [];
  originalResponse: any;
  sortOrder: string = 'asc';
  searchQuery: string = '';
  exportFileName: string = 'Export_List';

  mainId: number | undefined;
  abstract sortField: string;
  abstract columns: TableColumnConfigurable[];
  abstract quickFilters: QuickFilterItem[];
  abstract filterCategories: FilterCategory[];
  extraParams: QueryParamDTO[] = []; //@todo late to be make extra param as abstract
  paginationConfig: PaginationConfig = defaultPaginationConfig;

  isDeleting: boolean = false;
  protected alertService: any; //TODO: Should Correct alertService1 name and pass in constructor later
  protected confirmService: any; //TODO: Should Correct confirmService1 name and pass in constructor later

  constructor() {
    makeObservable(this, {
      items: observable,
      sortOrder: observable,
      searchQuery: observable,
      paginationConfig: observable,
      isLoading: observable,
      isInitialLoading: observable,
      isEmptyList: observable,
      setSort: action,
      setSearchQuery: action,
      setPage: action,
      loadItems: action,
      resetEntireState: action,
      toggleColumn: action,
      getEnabledColumns: observable,
      isDeleting: observable,
    });
  }

  initialize(): void {} //@todo will make abstract later
  abstract get service(): any;
  protected mainConv: any; //@todo will make abstract later
  abstract resetEntireState(): void;
  abstract getMasterLists(): void;
  triggerItemsLoaded() {}

  //@todo will make abstract later
  convertResponseToListDTO(response: any): T[] {
    if (this.mainConv) {
      //@todo to be removed and mainconv will be mandatory mandatory for sub class
      return this.mainConv.resToList(response);
    }
    return response;
  }

  setSort(event: { field: string; order: string }): void {
    this.sortField = event.field;
    this.sortOrder = event.order;
    this.loadItems();
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query;
    this.loadItems();
  }

  setPage(page: number): void {
    this.paginationConfig.currentPage = page;
    this.loadItems();
  }

  refresh(): void {
    this.searchQuery = '';
    this.paginationConfig.currentPage = 1;
    this.loadItems();
  }

  loadItems(): void {
    this.isLoading = true;
    const activeFilter = this.quickFilters.find((filter) => filter.isActive);
    const activeFilterKey = activeFilter ? activeFilter.key : undefined;
    const dynamicFilters = this.getAllSelectedIds();

    let listCall;

    if (this.mainId) {
      listCall = this.service.list(
        this.mainId,
        this.sortField,
        this.sortOrder,
        this.paginationConfig.currentPage,
        this.paginationConfig.pageSize,
        this.searchQuery,
        activeFilterKey,
        dynamicFilters,
        this.extraParams
      );
    } else {
      listCall = this.service.list(
        this.sortField,
        this.sortOrder,
        this.paginationConfig.currentPage,
        this.paginationConfig.pageSize,
        this.searchQuery,
        activeFilterKey,
        dynamicFilters,
        this.extraParams
      );
    }

    listCall.subscribe({
      next: (response: any) => {
        runInAction(() => {
          this.originalResponse = response;
          //with pagination
          if (this.isPagination) {
            this.items = this.convertResponseToListDTO(response.items);
            this.paginationConfig.totalPages = response.pagination.total_pages;
            this.paginationConfig.totalCount = response.pagination.total_count;
            this.paginationConfig.pages = generatePages(
              this.paginationConfig.currentPage,
              this.paginationConfig.totalPages
            );
            this.isEmptyList = this.paginationConfig.totalCount <= 0;
          } else {
            //without pagination
            this.isEmptyList = response.length == 0;
            this.items = this.convertResponseToListDTO(response);
          }
          this.isInitialLoading = false;
          this.isLoading = false;
          this.triggerItemsLoaded();
        });
      },
      error: (error: any) => {
        console.error('Failed to load data:', error);
        this.isLoading = false;
      },
    });
  }

  toggleColumn(field: string): void {
    toggleColumns(this.columns, field);
  }

  getEnabledColumns(): TableColumn[] {
    return this.columns.filter((column) => column.isEnable);
  }

  deleteItem(ids: number | number[]): void {
    this.confirmService.confirmDeleteItem().then((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.isDeleting = true;

        // Ensure ids is always an array
        if (!Array.isArray(ids)) {
          ids = [ids];
        }

        // Determine the correct delete call based on the presence of mainId
        const deleteCall = this.mainId
          ? this.service.delete(this.mainId, ids)
          : this.service.delete(ids);

        // Subscribe to the delete call observable
        deleteCall.subscribe({
          next: () => {
            this.isDeleting = false;
            this.alertService.success('Deleted!', 'Success');
            this.loadItems();
          },
          error: (error: any) => {
            this.isDeleting = false;
            this.alertService.error('Error while deleting!', 'Error!');
            console.error('Error while deleting', error);
          },
        });
      }
    });
  }

  exportItems(): void {
    const activeFilter = this.quickFilters.find((filter) => filter.isActive);
    const activeFilterKey = activeFilter ? activeFilter.key : undefined;
    const dynamicFilters = this.getAllSelectedIds();
 
    const today = new Date().toISOString().replace(/-/g, '').slice(0, 8); // Compact date format
    const fileName = `${this.exportFileName}_${today}.xlsx`; // Complete file name with extension

    this.service.export(activeFilterKey,dynamicFilters).subscribe({
      next: (response: any) => {
        let userAgent = navigator.userAgent.toLowerCase();

        if (response instanceof Blob && response.size > 0) {
          // Process the blob if the response is valid
          if (userAgent.indexOf('trident') === -1) {
            // Non-IE browsers
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([response], { type: response.type }));
            downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink); // Clean up after the download
          } else {
            // IE browser (using msSaveBlob)
            const navigatorAny = navigator as any; // Cast navigator to any to avoid TypeScript error
            if (navigatorAny.msSaveBlob) {
              navigatorAny.msSaveBlob(response, fileName);
            } else {
              console.error('File saving not supported in this browser.');
            }
          }
        } else {
          // Handle case when the response is not a valid Blob
          this.alertService.error('Invalid response received while exporting!', 'Error!');
          console.error('Invalid response received:', response);
        }
      },
      error: (error: any) => {
        // Check if it's a Blob error response and try to read the message
        if (error instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const errorMessage = JSON.parse(reader.result as string)?.message || 'Unknown error';
            this.alertService.error(`Error while exporting: ${errorMessage}`, 'Error!');
          };
          reader.readAsText(error);
        } else {
          // Generic error message
          this.alertService.error('Error while exporting!', 'Error!');
          console.error('Error while exporting', error);
        }
      },
    });
  }



  getAllSelectedIds(): SelectedFilterIds {
    const selectedIds: SelectedFilterIds = {};

    this.filterCategories.forEach((category) => {
      selectedIds[category.key] = category.options
        .filter((option) => option.isSelected)
        .map((option) => parseInt(option.id));
    });

    return selectedIds;
  }
}
